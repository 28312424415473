<!--
 * @Author: your name
 * @Date: 2022-04-23 14:03:55
 * @LastEditTime: 2022-04-23 14:40:38
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \app\src\components\Nav.vue
-->
<template>
    <div class="nav">
        <div  @click="goback">
            
        <img src="../assets/icon/back.png" class="back" alt="" >    
        </div>
    </div>    
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods:{
        goback(){
            this.$router.back()
        }
    }
}
</script>

<style lang="less" scoped>
@Color: #e23d6d;
// 弹性布局
.flex(@JC: center; @FW: nowrap; @AI: center; @FD: row) {
  display: flex;
  flex-wrap: @FW;
  flex-direction: @FD;
  justify-content: @JC;
  align-items: @AI;
}

// 文字超出省略号
.text-overflow(@num: 1) {
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: @num;
}
.nav{
    .flex(space-between);
    .back{
        width: 5vw;
        height: 5vw;
        margin-top: 5vw;
    }
}
</style>